import React, { useState, useContext, useEffect } from "react"
import classNames from "classnames"

import DropzoneBody from "./UploadDropzoneBody"
import DropzoneLabel from "./UploadDropzoneLabel"

import styles from "../utils/upload.module.scss"
import { EpharmacyContext } from "../../Epharmacy/EpharmacyContext/EpharmacyContext"

const UploadDropzone = ({
  label,
  icon,
  fileInputRef,
  documents,
  handleFileChooser,
  handleFileRead,
  maxFileCount,
  notifications = [],
  id,
  t,
}) => {
  const [filesUploaded, setFilesUploaded] = useState(documents)

  useEffect(() => {
    setFilesUploaded(documents)
  }, [documents])
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)

  return (
    <div
      className={classNames("container mt-1", styles["dropzone"])}
      id={id || "uploadField"}
    >
      <input
        type="file"
        className={styles["dropzone__input"]}
        ref={fileInputRef}
        onChange={handleFileRead}
        multiple
        accept="image/*, .pdf"
        value=""
      />

      {notifications.length > 0 && notifications.map((item) => item)}
      <div
        className={classNames(styles["dropzone__container"])}
        onClick={handleFileChooser}
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === "Enter") handleFileChooser()
        }}
      >
        <DropzoneBody
          icon={icon}
          label={label}
          state={epharmacyState}
          dispatch={epharmacyDispatch}
          notifications={notifications}
          filesUploaded={filesUploaded}
          setFilesUploaded={setFilesUploaded}
        />

        {filesUploaded.length < maxFileCount && <DropzoneLabel label={label} />}
      </div>
    </div>
  )
}

export default UploadDropzone
