import React, { Fragment, useState, useRef, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import ActionButtons from "elements/ActionButtons"
import UploadDropzone from "./UploadDropzone"
import UploadErrorNotification from "./UploadDropzone/UploadErrorNotification"
import UploadGuidelinesModal from "../Elements/UploadDocumentDropzone/UploadGuidelinesModal"

import { isIeOrEdge } from "../../services/browserCompatibility"
import { uploadDocument } from "./services/uploadDocuments"

import acceptedFileTypes from "./utils/acceptedFileTypes.json"
import { isBrowser } from "../../services/general"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { AppContext } from "../../context/AppContext"
import UploadGuidelinesMobileModal from "../Elements/UploadDocumentDropzone/UploadGuidelinesMobileModal"

import useSaxendaCoupons from "../Epharmacy/Order/hooks/useSaxendaCoupons"
import AddMedicineModal from "../Epharmacy/AddMedicineModal"
import useAssistanceCalculatorImages from "../Epharmacy/Order/hooks/useAssistanceCalculatorImages"
import { medicine } from "./utils/staticItems"
import FormRequiredFields from "../Elements/Form/FormRequiredFields"
import Message from "../Elements/Message"

const Upload = ({
  pageContext,
  maxFileSize,
  maxFileCount,
  icon,
  dropzoneLabel,
  cartContainer,
  documents,
  state,
  dispatch: moduleDispatch,
  alreadyHasButtons,
  type,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { dispatch } = useContext(AppContext)
  const fileInputRef = useRef(null)
  const [notifications, setNotifications] = useState([])
  const [filesUploaded, setFilesUploaded] = useState(documents)
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  const imageData = useAssistanceCalculatorImages()

  const isValid = filesUploaded?.length > 0
  useEffect(() => {
    setFilesUploaded(documents)
  }, [documents])

  const handleFileChooser = async () => {
    fileInputRef.current.click()
    dispatch({ type: "HIDE_MODAL" })
    dispatch({ type: "CLOSE_SLIDING_MODAL" })
    await delay(400)
    dispatch({ type: "HIDE_SLIDING_MODAL" })
  }

  const openFileChooser = () => {
    if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
    else handleFileChooser()
  }
  const handleShowGuidelines = () => {
    if (filesUploaded.length > 0) {
      if (filesUploaded.length < maxFileCount) {
        openFileChooser()
      }
      return
    }
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Upload Guidelines",
        isCard: false,
        hideCloseButton: false,
        isActive: true,
        icon: faCamera,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold  has-text-centered`,
        content: <UploadGuidelinesModal handleCloseModal={openFileChooser} />,
      },
    })
    dispatch({
      type: "SHOW_SLIDING_MODAL",
      payload: {
        isCard: false,
        hideCloseButton: false,
        isActive: true,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold  has-text-centered`,
        content: (
          <UploadGuidelinesMobileModal
            handleCloseModal={openFileChooser}
            heading="Upload Guidelines"
          />
        ),
      },
    })
  }

  const handleAlreadyUploaded = (setNotifications) => (file) => {
    setNotifications((notificationsList) => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
  }

  const closeNotifications = () => {
    setNotifications([])
  }

  const checkInvalidFileType = ({ filesUploaded }) => {
    return filesUploaded.every((file) => {
      return !acceptedFileTypes.includes(file.type)
    })
  }

  const handleFileRead = (event) => {
    closeNotifications()
    const tempFilesUploaded = [...event.target.files]
    const initialNotifications = []
    const hasExceededMaxFiles =
      tempFilesUploaded.length + filesUploaded?.length > maxFileCount

    const hasInvalidFileType = checkInvalidFileType({
      filesUploaded: tempFilesUploaded,
    })
    switch (true) {
      case hasExceededMaxFiles:
        initialNotifications.push(
          <UploadErrorNotification
            message={`Please upload only a maximum of ${maxFileCount} files.`}
          />
        )
        break
      case hasInvalidFileType:
        initialNotifications.push(
          <UploadErrorNotification
            message={`You may only upload files in the following format: jpeg, jpg, png, or pdf files.`}
          />
        )
        break
      default:
        for (let i = 0; i < tempFilesUploaded.length; i++) {
          if (tempFilesUploaded[i].size < maxFileSize)
            uploadDocument(
              tempFilesUploaded[i],
              filesUploaded,
              setFilesUploaded,
              (prevFilesUploaded) => {
                moduleDispatch({
                  type: "SAVE_DOCUMENTS",
                  payload: [...prevFilesUploaded],
                })

                moduleDispatch({
                  type: "SAVE_CONTEXT_TO_SESSION",
                  payload: {
                    documents: [...prevFilesUploaded],
                  },
                })
              },
              handleAlreadyUploaded(setNotifications),
              type === "patient" ? "id" : "rx"
            )
          else
            initialNotifications.push(
              <UploadErrorNotification
                fileName={tempFilesUploaded[i].name}
                message="is greater than 2MB. Please upload a file or photo less than 2MB."
              />
            )
        }
        break
    }

    setNotifications(initialNotifications)
  }
  return (
    <Fragment>
      <UploadDropzone
        label={dropzoneLabel}
        id={"uploadPrescription"}
        icon={icon}
        fileInputRef={fileInputRef}
        handleFileChooser={handleShowGuidelines}
        handleFileRead={handleFileRead}
        notifications={notifications}
        documents={filesUploaded}
        maxFileCount={maxFileCount}
      />
      {!isValid && isSubmitted && (
        <p className="has-text-danger">{"Please upload your prescription."}</p>
      )}
      {alreadyHasButtons ? null : (
        <ActionButtons
          back={{
            label: <FontAwesomeIcon icon={faChevronLeft} />,
            link: pageContext.backPath,
          }}
          next={{
            label: "Next: Order Medicines",
            callback: () => {
              setIsSubmitted(true)
              if (!isValid) {
                navigate("#uploadPrescription")
              } else {
                navigate(pageContext?.nextPath)
              }
            },
          }}
        />
      )}
    </Fragment>
  )
}

export default Upload
