import React, { Fragment, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import Button from "./Button"

import styles from "./utils/elements.module.scss"
import Container from "../Layout/Container"
import { isBrowser } from "../../services/general"

const ResponsiveActionButtons = ({ isMobile, submit, next, back }) => (
  <Fragment>
    <div
      className={classNames(
        "is-flex is-justify-content-center",
        styles["buttons_container"],
        isMobile ? "is-hidden-tablet" : "is-hidden-mobile"
      )}
    >
      {back && (
        <button
          type="button"
          onClick={() => {
            if (back.callback) {
              back.callback()
              return
            }
            navigate(back.link)
          }}
          className={classNames(
            "mr-1 mr-1-mobile	mr-0-mobile button has-text-secondary",
            {
              "is-hidden-desktop is-hidden-tablet": !!isMobile,
              "is-hidden-mobile": !isMobile,
            },
            styles["back_button"]
          )}
        >
          {back.label}
        </button>
      )}

      {next && (
        <Button
          onClick={() => {
            if (next.callback) {
              next.callback()
              return
            }
            if (next?.link) navigate(next.link)
          }}
          className={classNames(
            "px-2 px-1-mobile ml-1 ml-0-mobile button is-secondary has-text-weight-bold",
            {
              "is-hidden-desktop is-hidden-tablet": !!isMobile,
              "is-hidden-mobile": !isMobile,
            },
            styles["next_button"]
          )}
          //isDisabled={next.disabled}
          isLoading={next.loading}
        >
          {next.label}
        </Button>
      )}

      {submit && (
        <Button
          className={classNames(
            "px-2 px-1-mobile ml-1 ml-0-mobile is-secondary has-text-weight-bold",
            {
              "is-hidden-desktop is-hidden-tablet": !!isMobile,
              "is-hidden-mobile": !isMobile,
            },
            styles["next_button"]
          )}
          type="submit"
          isLoading={submit.loading}
          //isDisabled={submit.disabled}
          onClick={() => navigate(submit.link)}
        >
          {submit.label}
        </Button>
      )}
    </div>
  </Fragment>
)

const ActionButtons = ({ submit, next, back }) => {
  const fixedContainerRef = useRef(null)
  let initial = {
    boxShadow:
      "0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)",
    backgroundColor: "#e6f0f0",
  }
  let onBottom = {
    boxShadow: "none",
    backgroundColor: "transparent",
  }
  let [containerStyling, setContainerStyling] = useState(initial)

  const handleScrolling = (e) => {
    if (window?.innerHeight + window?.scrollY >= document.body.offsetHeight) {
      setContainerStyling(onBottom)
    } else {
      // if (containerStyling?.backgroundColor !== initial.backgroundColor)
      setContainerStyling(initial)
    }
  }

  useEffect(() => {
    if (isBrowser())
      setContainerStyling(
        window?.innerHeight + window?.scrollY >= document?.body?.offsetHeight
          ? onBottom
          : initial
      )
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleScrolling(e))

    return () => {
      window.removeEventListener("scroll", (e) => handleScrolling(e))
    }
  }, [isBrowser() && window])

  return (
    <div style={{ height: "min-content" }}>
      <div
        className="is-bottom is-left is-light"
        ref={fixedContainerRef}
        style={{
          position: "fixed",
          width: "100vw",
          zIndex: 1,
          transition: "background-color 0.4s ease, box-shadow 0.4s ease",
          ...containerStyling,
        }}
      >
        <Container isCentered>
          <div className="buttons is-centered pt-2 pb-1 pt-1-mobile pb-1-mobile">
            <ResponsiveActionButtons submit={submit} next={next} back={back} />
            <ResponsiveActionButtons
              isMobile
              submit={submit}
              next={next}
              back={back}
            />
          </div>
        </Container>
      </div>
      {fixedContainerRef.current && (
        <div style={{ height: fixedContainerRef.current.clientHeight }}></div>
      )}
    </div>
  )
}

export default ActionButtons
