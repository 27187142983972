import React, { Fragment } from "react"
import Button from "../Button"
import uploadGuidelines from "../utils/uploadGuidelines.json"

const UploadGuidelinesModal = ({ props, handleCloseModal }) => {
  return (
    <Fragment>
      <div className="is-flex is-justify-content-left">
        <ol className="ml-1">
          {(props?.guidelines || uploadGuidelines).map((item, index) => (
            <li className="has-text-left" key={index}>
              {item}
            </li>
          ))}
          <Button
            className="button button-size is-secondary mt-2"
            onClick={handleCloseModal}
          >
            I understand
          </Button>
        </ol>
      </div>
    </Fragment>
  )
}

export default UploadGuidelinesModal
