import React, { Fragment } from "react"
import uploadGuidelines from "../utils/uploadGuidelines.json"
import Button from "../Button"

const UploadGuidelinesMobileModal = ({ props, handleCloseModal, heading }) => {
  return (
    <Fragment>
      {heading && <h3 className="has-text-primary mt-2 mb-1 ">{heading}</h3>}

      <div className="is-flex is-justify-content-left">
        <ol className="ml-1">
          {(props?.guidelines || uploadGuidelines).map((item, index) => (
            <li className="has-text-left" key={index}>
              {item}
            </li>
          ))}
          <Button
            className="button-size is-primary mt-2"
            onClick={handleCloseModal}
          >
            I understand
          </Button>
        </ol>
      </div>
    </Fragment>
  )
}

export default UploadGuidelinesMobileModal
