import React, { useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../Layout/index"
import Container from "../../Layout/Container"
import Upload from "../../Upload"
import guidelines from "../utils/uploadGuidelines.json"
import { EpharmacyContext } from "../EpharmacyContext/EpharmacyContext"
import Section from "../../Elements/Section"
import Message from "../../Elements/Message"

const EpharmacyUpload = ({ pageContext }) => {
  const { module } = pageContext
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)

  useEffect(() => {
    epharmacyDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    //eslint-disable-next-line
  }, [epharmacyDispatch])

  const data = useStaticQuery(graphql`
    {
      document: file(relativePath: { eq: "icons/upload__document.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prescription: file(
        relativePath: { eq: "icons/upload__prescription.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const handleDeleteDocument = (index, fileName) => {
    let tempDocuments = [...epharmacyState?.documents]
    tempDocuments = tempDocuments.filter(
      (document) => document.name !== fileName
    )

    epharmacyDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: { ...epharmacyState, documents: [...tempDocuments] },
    })

    epharmacyDispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...tempDocuments],
    })
  }

  const fileUploadPrescription = data?.prescription?.childImageSharp?.fixed
  const MAX_FILE_SIZE_IN_BYTES = 5242880
  const MAX_FILE_COUNT = 3

  return (
    <Layout
      {...module}
      display={{
        footer: false,
        helpCenterBanner: false,
        bottomMargin: true,
        progressBar: true,
      }}
      pageContext={pageContext}
      currentStep={1}
      totalSteps={3}
    >
      <Container isCentered>
        <Section title={""} isSectionRequired={true}>
          <Message color="light">
            <p>Valid prescriptions should be:</p>
            <ul>
              <li>Have the patient's name</li>
              <li>Be issued within the last three months</li>
              <li>Have the doctor's name and PRC number</li>
              <li>
                Be issued by a doctor from Maxicare's Primary Care Network
              </li>
            </ul>
          </Message>
          <Upload
            pageContext={pageContext}
            icon={fileUploadPrescription}
            maxFileCount={MAX_FILE_COUNT}
            maxFileSize={MAX_FILE_SIZE_IN_BYTES}
            guidelines={guidelines}
            documents={epharmacyState?.documents}
            medicines={epharmacyState?.medicines}
            dispatch={epharmacyDispatch}
            state={epharmacyState}
            dropzoneLabel={"Upload Prescription"}
            type={"epharmacy-prescription"}
          />
        </Section>
      </Container>
    </Layout>
  )
}

export default EpharmacyUpload
